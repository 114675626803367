
<style lang="scss" scoped>
.EditUserINfo {
  width: 100%;
  background: #eee;
  height: 100vh;
  .sex {
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin-top: 20px;
  }
  .avatar {
    width: 30px;
    height: 30px;
  }
  /* 样式穿透 */
  /deep/ .van-image-preview__cover {
    /* 取消样式设置 */
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    .van-nav-bar {
      background: #000;
    }
  }
  .foot {
    margin-top: 50%;
    .btn {
      width: 90%;
      height: 40px;
      background: #eb4541;
      margin: 0 5%;
      border-radius: 15px;
      border: 0;
      color: #fff;
    }
  }
}
</style>
<template>
  <div class="EditUserINfo">
    <van-nav-bar title="编辑资料"
                 left-arrow
                 @click-left="$router.back()"></van-nav-bar>
    <div style="margin-top:20px">
      <van-cell-group>
        <van-cell title="头像"
                  is-link>
          <!-- <van-image class="avatar" round :src="this.images[0]" /> -->
          <van-uploader :max-size="10000 * 5024"
                        @oversize="onOversize"
                        :max-count='1'
                        v-model="fileList"
                        :show-upload="isshow"
                        multiple
                        :after-read="afterRead" />
        </van-cell>
        <!-- 表单元素的hidden属性：隐藏表单元素 -->

        <input type="file"
               hidden
               ref="file"
               @change="onFileChange" />
        <van-field maxlength='8'
                   v-model="name"
                   label="用户名" />
        <van-field readonly
                   clickable
                   name="picker"
                   :value="value"
                   label="性别"
                   placeholder="点击选择性别"
                   @click="showPicker = true" />
        <van-popup v-model="showPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="columns"
                      @confirm="onConfirm"
                      @cancel="showPicker = false" />
        </van-popup>

      </van-cell-group>
    </div>

    <!-- 头像预览,预览关闭文件重置 -->

    <div class="foot"
         @click="info">
      <button class="btn">保存信息</button>
    </div>
  </div>
</template>
 
<script>
import { newdeta_api, newinfo_api } from '../http/api/certification'
import { Toast } from 'vant'
export default {
  name: 'EditUserINfo',
  components: {},
  props: {},
  data () {
    return {
      isshow: true,
      fileList: [],
      userobj: {},
      name: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
      value2: null,
      showPicker2: false,
      value: '',
      columns: ['男', '女'],
      showPicker: false,
      show: false,
      user: {
        photo: 'https://img.yzcdn.cn/vant/cat.jpeg',
        name: '凯文',
        gender: '男',
        birthday: '1996-12-18',
      }, //用户信息
      isPreviewShow: false,
      images: '' //预览图片列表
    }
  },
  watch: {},
  computed: {

    file () {
      return this.$refs['file']
    },
  },
  methods: {
    async userinfo () {
      let res = await newinfo_api({

      })
      if (res.code == 200) {
        this.headimg = res.data.headImg
        this.name = res.data.userName
        this.value = res.data.sex

        var info = {
          'url': res.data.headImg
        }
        this.fileList.push(info);
        if (this.value == 2) {
          this.value = '女'
        } else {
          this.value = '男'
        }
      }

    },
    //上传图片
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file)
      let canvas = document.createElement('canvas'); // 创建Canvas对象(画布)
      let context = canvas.getContext('2d');
      let img = new Image();
      img.src = file.content;                         // 指定图片的DataURL(图片的base64编码数据)
      img.onload = function () {
        // 画布大小 这里的this指向img
        canvas.width = this.width;
        canvas.height = this.height;
        context.drawImage(img, 0, 0, this.width, this.height);  // 图片大小
        file.content = canvas.toDataURL(file.file.type, 0.3);   // 0.92为默认压缩质量
      };
      let data = new FormData()
      data.append('file', file.file)

      file.status = 'uploading';
      file.message = '上传中...';
      this.$http
        .post('https://mihuatang.xyz/user/sys/file/upload', data)
        .then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            file.status = 'done ';
            this.images = res.data.data.url
            var img = JSON.parse(sessionStorage.getItem('token'))
            console.log(img)
            img.headImg = res.data.data.url
            console.log(this.fileList);
            sessionStorage.setItem('token', JSON.stringify(img))
          }
        }).catch((err) => {
          console.log(err);
          setTimeout(() => {
            file.status = 'failed';
            file.message = '上传失败';
          }, 3000);
        })
    },
    //大小限制
    onOversize (file) {
      console.log(file);
      Toast('文件大小不能超过 10M');
    },
    info () {
      this.deta()
    },
    async deta () {
      var s = 1
      if (this.value == '女') {
        s = 2
      } else {
        s = 1
      }
      let res = await newdeta_api({
        userName: this.name,
        sex: s, //性别: 1:男 2:女
        headImg: this.images,
      })
      console.log(res)
      console.log(this.images);
      if (res.code == 200) {
        var user = JSON.parse(sessionStorage.getItem('token'))
        console.log(user)
        user.userName = this.name
        user.sex = s
        sessionStorage.setItem('token', JSON.stringify(user))

        this.$router.back()
      }
    },

    onConfirm2 (time) {
      console.log(time)
      this.value2 = time
      this.showPicker2 = false
    },
    onConfirm (value) {
      console.log(value)
      this.value = value
      this.showPicker = false
    },
    showPopup () {
      this.show = true
    },
    onAvatarClick () {
      this.file.click()
    },
    onFileChange () {
      // 1.拿到file类型input选择的文件对象
      const fileObj = this.file.files[0]
      // 2.使用window.URL.createObjectURL(file)得到文件数据
      const fileData = window.URL.createObjectURL(fileObj)
      // 3.将img.src = 第2步的结果
      this.images = [fileData]
      var img = JSON.parse(sessionStorage.getItem('token'))
      console.log(img)
      img.headImg = this.images

      sessionStorage.setItem('token', JSON.stringify(img))
      //sessionStorage.setItem("headImg",JSON.stringify(this.images))
      //console.log( this.images);
      this.isPreviewShow = true
    },
    onUpdateAvatar () {
      // 1.构造包含文件数据的表单对象
      // 注意：含有文件的服务务必要放到FormData中
      // 用代码来构造一个表单对象，主要目的是用来异步发送文件上传
      // 参数一：键
      // 参数二：值
      const fd = new FormData()

      fd.append('photo', this.file.files[0])
      console.log(fd)
      this.$toast.loading({
        duration: 1000,
        message: '保存中',
        forbidClick: true,
      })
      this.isPreviewShow = false
      //this.$toast.success('保存成功')
    },
  },
  created () {
    this.userinfo()
    //     let user = JSON.parse(sessionStorage.getItem('token'))
    //     console.log(user);
    //     this.name = user.userName

    // var info= {
    //   'url':user.headImg
    // }
    // this.fileList.push(info);
    //    //imgobj = user.headImg
    //   // console.log(imgobj);
    //     this.value = user.sex
    //     if (this.value == 2) {
    //       this.value = '女'
    //     } else {
    //       this.value = '男'
    //     }

  },
  mounted () { },
}
</script>
